<template>
  <!-- <video autoplay muted loop id="myVideo">
    <source :src="service.video" type="video/mp4" />
  </video> -->
  <section class="sec_content" :data-sec="service.title">
    <div id="topbar">
      <div class="logo_lang">
        <!-- <img id="logo" src="../assets/logo.png" alt="tdzone" /> -->
        <div class="dropdown">
          <button class="dropbtn">
            <span v-if="$i18n.locale == 'en'"> English </span>
            <span v-if="$i18n.locale == 'ku'"> کوردی </span>
            <span v-if="$i18n.locale == 'ar'"> عربی </span>
          </button>
          <div class="dropdown-content">
            <span @click="changeLang('en')">English</span>
            <span @click="changeLang('ku')">کوردی</span>
            <span @click="changeLang('ar')">عربی</span>
          </div>
        </div>
      </div>

      <p class="serviceName">
        {{ $t("companyName") }} <span>{{ $t("homeTitle") }}</span>
      </p>
    </div>

    <div class="sevice_carousel">

      <carousel v-model="slideNumber" v-if="services.length > 0" :touch-drag="true" :mouse-drag="true"
        :items-to-scroll="1" :breakpoints="{
          // 700px and up
          300: {
            itemsToScroll: 1,
            mouseDrag: true,
            touchDrag: true,
            itemsToShow: 2,
            snapAlign: 'center',
          },
          425: {
            itemsToScroll: 1,
            mouseDrag: true,
            touchDrag: true,
            itemsToShow: 2,
            snapAlign: 'center',
          },
          768: {
            itemsToScroll: 1,
            mouseDrag: true,
            touchDrag: true,
            itemsToShow: 4,
            snapAlign: 'center',
          },
          992: {
            itemsToScroll: 1,
            mouseDrag: true,
            touchDrag: true,
            itemsToShow: 6,
            snapAlign: 'center',
          },
          1200: {
            itemsToScroll: 1,
            mouseDrag: true,
            touchDrag: true,
            itemsToShow: 9,
            snapAlign: 'center',
          },
        }">

        <slide v-for="service in services" @click="showDetail(service.service_name_en)" :key="service.id">
          <div class="item">
            <img :src="servicePath + '/' + service.service_coverpic" alt="" />
            <p>{{ service["service_name_" + $i18n.locale] }}</p>
          </div>
        </slide>


      </carousel>

    </div>

  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import servicesData from "@/mixins/servicesData";


import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: "ComedyTime",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      slideNumber: 0,
      phoneNumber: "",
      count: 0,
      color: "red",
      services: {},
      service: {},
      primaryColor: "",
      servicesContentData: [
        {
          title: "comedy",
          id: 1,
          image: "bg",
        },
        {
          title: "Religion",
          id: 2,
          image: "bg Religion",
        },
      ],
    };
  },
  directives: { mask },
  methods: {
    showDetail(title) {
      this.$router.push("subscribtion/" + title.toLowerCase());
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("tdZone_lang", lang);
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang");
    },
  },
  mounted() {
    servicesData.find((service) => {
      if (this.$route.params.id) {
        if (service.title == this.$route.params.id) {
          this.service = service;
          this.primaryColor = service.primary_color;
        }
      } else {
        if (service.title == "comedy") {
          this.service = service;
          this.primaryColor = service.primary_color;
        }
      }
    });
  },
  created() {
    this.$http.get(this.baseUrl + "/services/grid").then((response) => {
      this.services = response.data.data;
    });
  },
};
</script>

<style  lang="scss">
.carousel__slide {
  height: 200px !important;
}
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
}

.sec_content {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;

  #topbar {
    position: relative;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0rem;

    @media (max-width: 500px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 768px) {
      margin: 0rem;
      flex-direction: row-reverse;
    }

    .logo_lang {
      display: flex;
      align-items: center;

      #logo {
        width: 180px;
        height: 60px;
        margin-right: 1.5rem;

        @media (max-width: 500px) {
          width: 120px;
          height: 40px;
        }

        @media (min-width: 500px) and (max-width: 768px) {
          width: 150px;
          height: 50px;
        }
      }
    }

    @media (max-width: 600px) {
      /* #logo {
        display: none;
      } */
    }

    .serviceName {
      color: #fff;
      font-weight: bold;
      font-size: 30px;

      @media (max-width: 500px) {
        font-size: 15px;

        span {
          display: block;
        }
      }

      @media (min-width: 500px) and (max-width: 768px) {
        // display: none;
        font-size: 20px;

        span {
          display: block;
        }
      }
    }
  }

  .sevice_carousel {
    position: absolute;
    bottom: 50px;
    height: 200px;
    width: 100%;
  }

  /*.carousel {
    position: absolute;
    bottom: 50px;
    height: 200px;
    width: 100%;
    z-index: 99999;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 2rem;
    justify-content: start;

    @media (min-width: 1440px) {
      justify-content: center;
    }

    .prev,
    .next {
      position: absolute;
      top: 50%;
      z-index: 9999999999;
      cursor: pointer;
      font-weight: 1200;
      font-size: 30px;
      color: white;
      font-weight: bold;
    }

    .prev {
      left: 20px;
      transform: translateY(-50%);
    }

    .next {
      right: 20px;
      transform: translateY(-50%);
    }

    .carouseltems {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      transition: 0.5s;
*/
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*   margin-right: 2rem;
        margin-left: 2rem; */
    cursor: pointer;

    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;

      @media(max-width:475px) {
        width: 80px;
        height: 80px;
      }
    }

    @media(min-width:767px) {
      img:hover {
        scale: 1.3;
        transition: 0.5s;
      }

      img:hover .carousel__slide {
        scale: 3;
        transition: 0.5s;
      }

      img:hover~p::after {
        width: 100%;
        transition: 1s;
      }

      img:hover~p {
        font-size: 20px;
        width: 100%;
        transition: 1s;
      }


    }

    p {
      color: #fff;
      position: relative;
      padding-bottom: 0.5rem;
    }

    p::after {
      position: absolute;
      bottom: 0px;
      width: 0px;
      height: 5px;
      content: "";
      left: 0px;
      background-color: #fff;
      transform-origin: center;
    }
  }
}

/*}
}*/


.sec_content::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;

  @media (max-width: 600px) {
    top: 0px;
    left: 0px;
    height: 100%;
  }
}

.sec_content[data-sec="learn"]::after {
  @media (min-width: 601px) {
    background-image: var(--learn-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--learn-home-image);
  }
}

.sec_content[data-sec="comedy"]::after {
  @media (min-width: 601px) {
    background-image: var(--comedy-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--comedy-home-image);
  }
}

.sec_content[data-sec="game"]::after {
  @media (min-width: 601px) {
    background-image: var(--game-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--game-home-image);
  }
}

.sec_content[data-sec="knowledge"]::after {
  @media (min-width: 601px) {
    background-image: var(--kn-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--kn-home-image);
  }
}

.sec_content[data-sec="ladies"]::after {
  @media (min-width: 601px) {
    background-image: var(--lad-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--lad-home-image);
  }
}

.sec_content[data-sec="entertainment"]::after {
  @media (min-width: 601px) {
    background-image: var(--ent-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--ent-home-image);
  }
}

.sec_content[data-sec="religion"]::after {
  @media (min-width: 601px) {
    background-image: var(--rel-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--rel-home-image);
  }
}

.sec_content[data-sec="meal"]::after {
  @media (min-width: 601px) {
    background-image: var(--mea-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--mea-home-image);
  }
}

.sec_content[data-sec="weather"]::after {
  @media (min-width: 601px) {
    background-image: var(--wea-home-image);
  }

  @media (max-width: 600px) {
    background-image: var(--wea-home-image);
  }
}

.dropbtn {
  padding: 0px;
  margin: 0px;
  border: none;
  border-bottom: 1px solid v-bind(primaryColor);
  width: 60px;
  height: 40px;
  background-color: transparent;

  span {
    color: #fff;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: v-bind(primaryColor);
  color: #fff;
  transition: 0.3s;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  transition: 0.5s;
  background-color: v-bind(primaryColor);
}
</style>
