const servicesData = [
  {
    id: 1,
    title: "comedy",
    title_en: "Comedy",
    title_ku: "کۆمیدی",
    title_ar: "كوميديا",
    primary_color: " #ff4d00",
    icon: require("../assets/icons/comedy.png"),
    thumbnail: "https://universe-files.dacast.com/8d0c7824-7249-fbcf-9b83-007d19f3a1d9",
    thmb: require("../assets/thumbnails/comedy_tmb.jpg"),
    video: "https://iframe.dacast.com/vod/1f36bbf9-78b2-0694-4b19-a48c09b423c2/50903fa0-d047-ad34-976f-2030ae7a85f0",
  },
  // {
  //   id: 6,
  //   title: "learn",
  //   title_en: "Learn",
  //   title_ku: "فێربوون",
  //   title_ar: "يتعلم",
  //   primary_color: "#2484e9",
  //   icon: require("../assets/icons/learn.png"),
  //   thumbnail: require("../assets/thumbnails/learn.jpg"),
  //   video: "https://videos.tdzone.net/learn.mp4",
  // },
  {
    id: 3,
    title: "game",
    title_en: "Game",
    title_ku: "یاری ئەلیکترۆنی",
    title_ar: "لعبة",
    primary_color: "#640fbf",
    icon: require("../assets/icons/game.png"),
    thumbnail: require("../assets/thumbnails/game.jpg"),
    // video: require("../assets/videos/game.mp4"),
  },
  {
    id: 4,
    title: "knowledge",
    title_en: "Knowledge",
    title_ku: "زانیاریدار",
    title_ar: "المعرفة",
    primary_color: "#7e4f35",
    icon: require("../assets/icons/kn.png"),
    thumbnail: require("../assets/thumbnails/knowledge.jpg"),
    //   video: require("../assets/videos/knowledge.mp4"),
  },
  {
    id: 5,
    title: "ladies",
    title_en: "Ladies",
    title_ku: "خانمان",
    title_ar: "سيداتي",
    primary_color: "#73135b",
    icon: require("../assets/icons/lad.png"),
    thumbnail: require("../assets/thumbnails/ladies.jpg"),
    // video: require("../assets/videos/ladies.mp4"),
  },
  {
    id: 2,
    title: "entertainment",
    title_en: "Entertainment",
    title_ku: "دڵخۆشکردن",
    title_ar: "وسائل الترفيه",
    primary_color: "#f36726",
    icon: require("../assets/icons/ent.png"),
    thumbnail: require("../assets/thumbnails/entertainment.jpg"),
    // video: require("../assets/videos/entertainment.mp4"),
  },
  {
    id: 6,
    title: "my religion",
    title_en: "My Relegion",
    title_ku: "ئایین",
    title_ar: "دِين",
    primary_color: "#8cb970",
    icon: require("../assets/icons/rel.png"),
    thumbnail: require("../assets/thumbnails/rel.jpg"),
    thmb: require("../assets/thumbnails/rel.jpg"),
    //  video: require("../assets/videos/rel.mp4"),
    video: "https://iframe.dacast.com/vod/1f36bbf9-78b2-0694-4b19-a48c09b423c2/7ad55774-b859-4a21-a604-466c3b71de6d",
  },
  {
    id: 7,
    title: "meal",
    title_en: "Meal",
    title_ku: "ژەمە خواردن",
    title_ar: "وجبة",
    primary_color: "#7fd013",
    icon: require("../assets/icons/mea.png"),
    thumbnail: require("../assets/thumbnails/meal.jpg"),
    //  video: require("../assets/videos/meal.mp4"),
  },
  {
    id: 9,
    title: "weather",
    title_en: "Weather",
    title_ku: "طقس",
    title_ar: "کەشوهەوا",
    primary_color: "#044271",
    icon: require("../assets/icons/wea.png"),
    thumbnail: require("../assets/thumbnails/weather.jpg"),
    //  video: require("../assets/videos/weather.mp4"),
  },
];

export default servicesData;
