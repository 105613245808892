<template>
  <section class="sec_content" :data-sec="serviceData.title">

    <loading v-model:active="isLoading" :color="serviceData.primary_color" is-full-page="true" />

    <div id="topbar">
      <router-link to="/"><img id="logo" :src="serviceData.icon" :alt="serviceData.title" /></router-link>
    </div>

    <div class="formContent">
      <div class="form-content">
        <div class="info">
          <label id="formLabel">{{ $t("otpMsg") }}</label>
          <div id="otp">
            <input v-model="pin1" 
             v-on:keyup="keyMonitor" maxlength="1" />
            <input v-model="pin2"  
              v-on:keyup="keyMonitor" maxlength="1" />
            <input v-model="pin3"  
             v-on:keyup="keyMonitor" maxlength="1" />
            <input v-model="pin4" 
              v-on:keyup="keyMonitor" maxlength="1" />
          </div>
          <button class="btn" id="cta_button" @click="sendOtp">
            {{ $t("subscribe") }}
          </button>
        </div>
        <div id="comedy_logo">
          <div class="comedyImg">
            <img :src="serviceData.icon" alt="" />
          </div>
          <label>{{ serviceData["title_" + $i18n.locale] }}</label>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import servicesData from "@/mixins/servicesData";
import notify from "../mixins/notifyMessage.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "ComedyTime",
  mixins: [notify],
  components: {
    Loading
  },
  data() {
    return {
      mainmessage: "Points: ",
      phoneNumber: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      serviceData: {},
      primaryColor: "",
      isLoading: false,
      checkUserStatusInterval: "",
    };
  },
  directives: { mask },
  methods: {
    keyMonitor(event) {

      


      if (event.key == 'Backspace' || event.key == 'Delete') {
        event.target.previousElementSibling.focus();
      } else {
        try {
       
          event.target.nextElementSibling.focus();
        } catch (error) {
          console.log(error);
        }
      }

    },
    sendOtp() {
      this.isLoading = true;
      let phoneNumber = this.$store.state.phoneNumber;
      let otp = this.pin1 + this.pin2 + this.pin3 + this.pin4;

      this.$http
        .get(
          this.baseUrl +
          "/dcp/verify2/" +
          phoneNumber +
          "/" +
          this.service.code +
          "/" +
          this.service["code_" + this.$i18n.locale] +
          "/" +
          this.service.spid +
          "/" +
          otp +
          "/" +
          this.$store.state.ti +
          "/" +
          this.getTimeStamp() + "?service_id=" + this.service.id
        )
        .then((response) => {
          var self = this;
          if (response.data.status == "Success") {
            if (response.data.token != null) {
              localStorage.setItem("token", response.data.token);
              this.$router.push("/categories/" + this.serviceData.title);
            } else {

              this.checkUserStatusInterval = setInterval(async function () {


                self.$http.get(self.baseUrl + "/user/actionType?msisdn=" + phoneNumber + "&service_id=" + self.serviceData.id)
                  .then((response) => {
                    if (response.data.actionType == 1) {
                      console.log("waiting");
                    } else if (response.data.actionType == 2) {
                      localStorage.setItem("token", response.data.token);
                      clearInterval(self.checkUserStatusInterval);
                      self.isLoading = false;
                      self.$router.push("/categories/" + self.serviceData.title);
                    } else if (response.data.actionType == 3) {
                      localStorage.setItem("token", response.data.token);
                      clearInterval(self.checkUserStatusInterval);
                      self.isLoading = false;
                      self.$router.push("/categories/" + self.serviceData.title);
                    }
                  })
              }, 5000);

            }
          } else {
            this.isLoading = false;

            var win = window;
            this.showMessage(this.$t(response.data.msg)).then(function () {
              self.$i18n.locale = response.data.user_lang;
              localStorage.setItem("tdZone_lang", response.data.user_lang);
              self.$http.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang");
              win.open("/subscribtion/" + self.$route.params.service + "/" + response.data.user_lang);
              //self.$router.push("/subscribtion/"+self.$route.params.service+"/"+response.data.user_lang);
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.errorMessage(this.$t("error"));
        });
    },
    getTimeStamp() {
      return parseInt(new Date().getTime() / 1000);
    },
    getServices() {
      this.$http
        .get(this.baseUrl + "/services/" + this.serviceData.id)
        .then((response) => {
          this.service = response.data;
        });
    }
  },
  created() {

  },
  mounted() {
    servicesData.find((service) => {
      if (this.$route.params.service) {
        if (service.title == this.$route.params.service) {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
        }
      } else {
        if (service.title == "comedy") {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
        }
      }

    });
    this.getServices();
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

/* Comedy New CSS */
body {
  margin: 0px !important;
  padding: 0px !important;
}

.sec_content {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
}

.sec_content::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;

  @media (max-width: 768px) {
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
  }
}

.sec_content {
  width: 100%;

  #topbar {
    position: relative;
    height: auto;
    display: flex;
    padding-left: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0rem;

    #logo {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 768px) {
      #logo {
        display: none;
      }
    }
  }

  .formContent {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;

    .form-content {
      display: flex;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 0px;
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }

      @media (min-width: 1024px) {
        width: 60%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 360px;
      height: 100%;

      @media (max-width: 425px) {
        width: 95%;
        margin-top: 300px;
      }

      @media (min-width: 425px) and (max-width: 768px) {
        margin-top: 230px;
        margin-bottom: 2rem;
      }

      #formLabel {
        font-size: 30px;
        color: v-bind(primaryColor);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 2rem;
      }

      #otp {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 2rem;

        input {
          height: 70px;
          width: 70px;
          text-align: center;
          background-color: #ddd;
          font-size: 30px;
          outline: none;
          border: none;
          border-radius: 8px;

          @media (max-width: 375px) {
            height: 60px;
            width: 60px;
          }
        }
      }

      .btn {
        width: 100%;
        height: 70px;
        font-size: 30px;
        background: v-bind(primaryColor);
        color: white;
        border-radius: 15px;
        outline: none;
        border: none;
        font-weight: 30px;
        cursor: pointer;

        @media (max-width: 425px) {
          width: 90%;
          height: 60px;
          font-size: 25px;
        }
      }
    }

    #comedy_logo {
      display: flex;
      flex-direction: column;
      border-radius: 6px;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .comedyImg {
        filter: drop-shadow(8px 8px 8px #aaa);
        border-radius: 40px;
        padding: 12px;
        width: 215px;
        height: 205px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 425px) {
          width: 150px;
          height: 135px;
        }
      }

      .comedyImg img {
        border-radius: 30px;
        width: 100%;
        height: 100%;
      }

      label {
        margin-top: 1rem;
        text-align: center;
        font-size: 40px;
        font-weight: 768;
        color: v-bind(primaryColor);

        @media (max-width: 768px) {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
}

.sec_content[data-sec="learn"]::after {
  @media (min-width: 769px) {
    background-image: var(--learn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--learn-mobile-image);
  }
}

.sec_content[data-sec="comedy"]::after {
  @media (min-width: 769px) {
    background-image: var(--comedy-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--comedy-mobile-image);
  }
}

.sec_content[data-sec="game"]::after {
  @media (min-width: 769px) {
    background-image: var(--game-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--game-mobile-image);
  }
}

.sec_content[data-sec="knowledge"]::after {
  @media (min-width: 769px) {
    background-image: var(--kn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--kn-mobile-image);
  }
}

.sec_content[data-sec="ladies"]::after {
  @media (min-width: 769px) {
    background-image: var(--lad-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--lad-mobile-image);
  }
}

.sec_content[data-sec="entertainment"]::after {
  @media (min-width: 769px) {
    background-image: var(--ent-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--ent-mobile-image);
  }
}

.sec_content[data-sec="my religion"]::after {
  @media (min-width: 769px) {
    background-image: var(--rel-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--rel-mobile-image);
  }
}

.sec_content[data-sec="meal"]::after {
  @media (min-width: 769px) {
    background-image: var(--mea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--mea-mobile-image);
  }
}

.sec_content[data-sec="weather"]::after {
  @media (min-width: 769px) {
    background-image: var(--wea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--wea-mobile-image);
  }
}
</style>
