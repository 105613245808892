const ku = {
  enterYourNumner: "ژمارە مۆبایلت داخڵ بکە",
  login:"چوونەژوورەوە",
  sendSms:"ناردنی نامە",
  otpMsg: "کۆد داخڵ بکە",
  subscribe:"بەشداری بکە",
  watchFree:"بە خۆڕایی سەیری بکە",
  points:"خاڵەکان",
  video:"ڤیدیۆ",
  image:"وێنە",
  text:"دەق",
  companyName:"تۆرنێت",
  homeTitle:"خزمەتگوزاری دیجیتاڵی ",
  success:"سەرکەوتوو بوو",
  confirmMsg:"تۆ دڵنیایت؟",
  cancel:"نەخێر",
  close:"دابخە",
  yes:"بەڵێ",
  invalidPhone:"ژمارەکەت هەڵەیە",
  error:"هەڵەیەک روویدا",
  subscribeInfo:'بەخێربێیت بۆ {service_name}! ڕۆژانە 5 خاڵ وەردەگریت بە بەشداریکردنی 300 د.ع ڕۆژانە. بۆ ڕاگرتنی بەشداریکردن، 0 بنێرە بۆ 3801 (بەخۆڕایی)'
};

export default ku;