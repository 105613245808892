import bcrypt from "bcryptjs";

const encryptionService = {
  encrypt(password) {
    const salt = bcrypt.genSaltSync(12);
    const pass =bcrypt.hashSync(password, salt);
    return pass;
  },
};

export default encryptionService;
