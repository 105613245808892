const en = {
  enterYourNumner: "Enter Your Number",
  login:"Login",
  sendSms: "Send SMS",
  otpMsg: "Enter Your Otp",
  subscribe:"Subscribe",
  watchFree:"Watch For free",
  points:"points",
  video:"Video",
  image:"Image",
  text:"Text",
  companyName:"Tornet",
  homeTitle:"Digital Services",
  success:"success",
  confirmMsg:"Are you Sure?",
  cancel:"Cancel",
  close:"Close",
  yes:"Yes",
  invalidPhone:"This Phone number invalid",
  error:"Error Occured",
  subscribeInfo:"Welcome to {service_name}! You will receive 5 points on a daily basis for the price of 300 IQD/day. To unsubscribe, send 0 to 3801 (Free of charge)"
};

export default en;
