import {
  createApp
} from "vue";

import App from "./App.vue";
import router from "./router";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import axios from "axios";
import VueAxios from "vue-axios";

import store from "./store.js";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import withUUID from "vue-uuid";

import i18n from "./services/i18n.service";

import ApiService from "@/services/api.service";

ApiService.setHeader();

const app = createApp(App);

app.config.globalProperties.baseUrl = "https://tdzone.net:3456";
app.config.globalProperties.servicePath = "https://tdzone.net:3456/service";
app.config.globalProperties.contentPath = "https://tdzone.net:3456/content";
app.config.globalProperties.korekBaseUrl =
  "https://korek-he.trendy-technologies.com";

app.use(router);
app.use(VueAxios, axios);
app.use(store);
app.use(VueSweetalert2);
app.use(withUUID);
app.use(i18n);

app.mount("#main");