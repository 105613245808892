<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { store } from "./store";

export default {
  name: "App",
  components: {},
  computed: {
    showLoading() {
      return store.showLoading;
    },
  },
  mounted() {},
};
</script>

<style>
body {
  margin: 0px !important;
  padding: 0px !important;
}


.swal2-popup {
  font-size: .7rem !important;
}

/* comedy color #ff4d00 */
/*  */

:root {
  --comedy-desktop-image: url("./assets/bg/desktop/comedy-bg.png");
  --comedy-mobile-image: url("./assets/bg/mobile/comedy-bg.png");
  --comedy-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --comedy-home-image: url("./assets/bg/home/comedy.png");

  --learn-desktop-image: url("./assets/bg/desktop/learn-bg.png");
  --learn-mobile-image: url("./assets/bg/mobile/learn-bg.png");
  --learn-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --learn-home-image: url("./assets/bg/home/learn.png");

  --game-desktop-image: url("./assets/bg/desktop/game-bg.png");
  --game-mobile-image: url("./assets/bg/mobile/game-bg.png");
  --game-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --game-home-image: url("./assets/bg/home/game.png");

  --kn-desktop-image: url("./assets/bg/desktop/kn-bg.png");
  --kn-mobile-image: url("./assets/bg/mobile/kn-bg.png");
  --kn-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --kn-home-image: url("./assets/bg/home/kn.png");

  --lad-desktop-image: url("./assets/bg/desktop/lad-bg.png");
  --lad-mobile-image: url("./assets/bg/mobile/lad-bg.png");
  --lad-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --lad-home-image: url("./assets/bg/home/lad.png");

  --ent-desktop-image: url("./assets/bg/desktop/ent-bg.png");
  --ent-mobile-image: url("./assets/bg/mobile/ent-bg.png");
  --ent-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --ent-home-image: url("./assets/bg/home/ent.png");

  
  --rel-desktop-image: url("./assets/bg/desktop/rel-bg.png");
  --rel-mobile-image: url("./assets/bg/mobile/rel-bg.png");
  --rel-mobile2-image: url("./assets/bg/mobile/rel-bg.png");
  --rel-home-image: url("./assets/bg/home/rel.png");

  --mea-desktop-image: url("./assets/bg/desktop/mea-bg.png");
  --mea-mobile-image: url("./assets/bg/mobile/mea-bg.png");
  --mea-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --mea-home-image: url("./assets/bg/home/mea.png");

  --wea-desktop-image: url("./assets/bg/desktop/wea-bg.png");
  --wea-mobile-image: url("./assets/bg/mobile/wea-bg.png");
  --wea-mobile2-image: url("./assets/bg/mobile/comedy-bg2.png");
  --wea-home-image: url("./assets/bg/home/wea.png");
}
</style>
