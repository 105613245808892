<template>
  <section class="sec_content" :data-sec="serviceData.title">
    <div id="comedyLogo">
      <div class="comedyLogoImg">
        <img :src="serviceData.icon" :alt="serviceData.title" />
      </div>
      <label>{{ serviceData["title_" + $i18n.locale] }}</label>
    </div>

    <div id="topbar">
      <router-link to="/">
        <!-- <img id="logo" :src="serviceData.icon" alt="tdzone"/> -->
      </router-link>
      <p class="points">{{ $t("points") }} : {{ userPoints }}</p>
    </div>

    <div class="formContent">
      <div class="form-content">
        <div class="info">
          <p class="points">{{ $t("points") }} : {{ userPoints }}</p>
          <div class="buttons">
            <button class="btn" v-for="child in childrens" :key="child.id" @click="goto(child.id)">
              {{ child["service_name_" + $i18n.locale] }}
            </button>
            <!-- <button
            class="btn"
            v-for="child in childrens"
            :key="child.id"
            @click="goto(child.id)"
          >
            {{ child.service_name_en }}
          </button> -->
          </div>
        </div>
        <div id="comedy_logo">
          <div class="comedyImg" @click="showVideo()">
            <img id="media" :src="serviceData.thumbnail" alt="" />
            <img id="playIcon" src="../assets/icons/play.png" alt="" />
          </div>
          <label>{{ $t("watchFree") }}</label>
        </div>
      </div>
    </div>

    <div :class="{ videoPopup: true, active: showPopup }" v-if="showPopup">
      <div @click="closeContent">
        <i class="fa fa-times closeBtn"></i>
      </div>

      <iframe :src="serviceVideo" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div :class="{
      overlay: true,
      active: showPopup,
    }"></div>
  </section>
</template>

<script>
import servicesData from "@/mixins/servicesData";

export default {
  name: "ComedyTime",
  data() {
    return {
      mainmessage: "Points: ",
      phoneNumber: "",
      childrens: [],
      userPoints: 0,
      serviceData: {},
      serviceVideo:"",
      primaryColor: "",
      showPopup: false,
    };
  },
  methods: {
    showVideo() {
      this.serviceVideo=this.serviceData.video;
      this.showPopup = true;
    },
    closeContent() {
      this.serviceVideo="";
      this.showPopup = false;
    },
    goto(id) {
      this.$router.push({ name: "Items", params: { id: id } });
    },
    getSubcategories() {
      this.$http
        .get(this.baseUrl + "/services/children/" + this.serviceData.id)
        .then((response) => {
          this.childrens = response.data.data;
        });
    },
    getPoints() {
      this.$http
        .get(this.baseUrl + "/user_point/" + this.serviceData.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.userPoints = response.data.point;
        });
    },
    checkUser() {
      if (localStorage.getItem("token")) {
        this.getSubcategories();
        this.getPoints();
      } else {
        this.$router.push("/");
      }
    }
  },
  created() {

  },
  mounted() {
    servicesData.find((service) => {
      if (this.$route.params.id) {
        if (service.title == this.$route.params.id) {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
        }
      } else {
        if (service.id == 'comedy') {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
        }
      }
    });
    this.checkUser();
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.sec_content {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  position: relative;
  overflow-x: hidden;

  #comedyLogo {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;

    @media (max-width: 425px) {
      top: 2rem;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      border-radius: 2px;
    }

    @media (min-width: 426px) and (max-width: 768px) {
      top: 1.5rem;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;
    }

    @media (min-width: 769px) {
      display: flex;
      flex-direction: column;
    }

    .comedyLogoImg {
      filter: drop-shadow(8px 8px 8px #aaa);
      border-radius: 40px;
      padding: 12px;
      width: 190px;
      height: 175px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 425px) {
        border-radius: 25px;
        width: 100px;
        height: 100px;
        margin-right: 1rem;
      }

      @media (min-width: 426px) and (max-width: 768px) {
        width: 140px;
        height: 130px;
        border-radius: 25px;
        margin-right: 1rem;
      }
    }

    .comedyLogoImg img {
      border-radius: 30px;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        width: 90%;
        height: 90%;
      }
    }

    label {
      margin-top: 1rem;
      text-align: center;
      font-size: 40px;
      font-weight: 768;
      color: #ffff;

      @media (max-width: 768px) {
        color: #fff;
        font-size: 30px;
        position: relative;
        top: 25px;
      }
    }
  }
}

.sec_content::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;

  @media (max-width: 768px) {
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
  }
}

.sec_content[data-sec="learn"]::after {
  @media (min-width: 769px) {
    background-image: var(--learn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--learn-mobile2-image);
  }
}

.sec_content[data-sec="comedy"]::after {
  @media (min-width: 769px) {
    background-image: var(--comedy-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--comedy-mobile2-image);
  }
}

.sec_content[data-sec="game"]::after {
  @media (min-width: 769px) {
    background-image: var(--game-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--game-mobile2-image);
  }
}

.sec_content[data-sec="knowledge"]::after {
  @media (min-width: 769px) {
    background-image: var(--kn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--kn-mobile2-image);
  }
}

.sec_content[data-sec="ladies"]::after {
  @media (min-width: 769px) {
    background-image: var(--lad-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--lad-mobile2-image);
  }
}

.sec_content[data-sec="entertainment"]::after {
  @media (min-width: 769px) {
    background-image: var(--ent-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--ent-mobile2-image);
  }
}

.sec_content[data-sec="my religion"]::after {
  @media (min-width: 769px) {
    background-image: var(--rel-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--rel-mobile2-image);
  }
}

.sec_content[data-sec="meal"]::after {
  @media (min-width: 769px) {
    background-image: var(--mea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--mea-mobile2-image);
  }
}

.sec_content[data-sec="wather"]::after {
  @media (min-width: 769px) {
    background-image: var(--wea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--wea-mobile2-image);
  }
}

.sec_content {
  width: 100%;

  #topbar {
    z-index: 999;
    position: relative;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0rem;

    @media (max-width: 768px) {
      margin: 0rem;
    }

    #logo {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 768px) {
      #logo {
        display: none;
      }
    }

    .points {
      color: v-bind(primaryColor);
      font-weight: bold;
      font-size: 30px;

      @media (max-width: 769px) {
        display: none;
      }
    }
  }

  .formContent {
    z-index: 999;
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;

    .form-content {
      display: flex;
      justify-content: start;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 200px;
      }

      @media (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        justify-content: center;
      }

      @media (min-width: 1024px) and (max-width: 1440px) {
        width: 90%;
      }

      @media (min-width: 1440px) {
        width: 80%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      height: 100%;

      @media (max-width: 768px) {
        margin-top: 0px;
        width: 100%;
      }

      .buttons {
        width: 100%;

        @media (min-width: 300px) and (max-width: 425px) {
          width: 90%;
        }

        @media (min-width: 425px) and (max-width: 540px) {
          width: 350px;
        }

        @media (min-width: 540px) and (max-width: 769px) {
          width: 420px;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          width: 90%;
        }

        @media (min-width: 1025px) and (max-width: 1440px) {
          width: 450px;
        }

        @media (min-width: 1441px) {
          width: 600px;
        }

        .btn {
          width: 40%;
          margin-left: 5%;
          height: 70px;
          font-size: 30px;
          background-color: white;
          color: white;
          border-radius: 20px;
          outline: none;
          border: none;
          font-weight: 30px;
          color: v-bind(primaryColor);
          margin-bottom: 0.5rem;
          filter: drop-shadow(8px 8px 8px #aaa);
          cursor: pointer;

          @media (max-width: 769px) {
            width: 100%;
            font-size: 20px;
            margin-left: 0px;
          }

          @media (min-width: 769px) and (max-width: 1024px) {
            width: 90%;
          }

          @media (min-width: 1025px) and (max-width: 1440px) {
            width: 45%;
            font-size: 20px;
          }

          @media (min-width: 1441px) {
            width: 45%;
            font-size: 25px;
          }
        }
      }

      .active {
        background: v-bind(primaryColor);
        color: white;
      }

      .points {
        color: v-bind(primaryColor);
        font-weight: bold;
        font-size: 30px;

        @media (min-width: 769px) {
          display: none;
        }
      }
    }

    #comedy_logo {
      margin-left: 5rem;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      background-color: transparent;
      cursor: pointer;

      @media (max-width: 769px) {
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .comedyImg {
        filter: drop-shadow(8px 8px 8px #aaa);
        border-radius: 6px;
        margin-top: 1rem;
        width: 420px;
        height: 250px;
        background-color: transparent;
        position: relative;

        @media (min-width: 300px) and (max-width: 425px) {
          width: 90%;
        }

        @media (min-width: 425px) and (max-width: 540px) {
          width: 350px;
        }

        @media (min-width: 540px) and (max-width: 769px) {
          width: 420px;
        }

        /* @media (min-width: 768px) and (max-width: 768px) {
          width: 300px;
        } */

        @media (min-width: 769px) and (max-width: 930px) {
          width: 350px;
        }
      }

      .comedyImg img#media {
        border-radius: 30px;
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .comedyImg img#playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
      }

      label {
        margin-top: 1rem;
        text-align: center;
        font-size: 30px;
        font-weight: 768;
        color: v-bind(primaryColor);

        @media (max-width: 768px) {
          //  color: #fff;
          font-size: 30px;
        }
      }
    }
  }
}

.videoPopup {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 9999999999;
  overflow: hidden;

  iframe {
    @media (max-width: 768px) {
      width: calc(92vw);
      height: 315px;
    }

    @media (min-width: 768px) {
      width: 560px;
      height: 315px;
    }
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    overflow: hidden;
    cursor: pointer;
    cursor: pointer;
  }
}

.overlay {
  background-color: black;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  transition: 0.2s;
}

.overlay.active {
  opacity: 0.5;
}

.videoPopup.active {
  display: block;
}
</style>
