<template>
  <section class="sec_content" :data-sec="serviceData.title">

    <loading v-model:active="isLoading" :color="serviceData.primary_color" is-full-page="true" />

    <div id="topbar">
      <router-link to="/"><img id="logo" :src="serviceData.icon" :alt="serviceData.title" /></router-link>
    </div>

    <div class="formContent" v-if="isSelectLanguage">
      <div class="form-content">
        <div class="info">
          <label id="formLabel">{{ $t("enterYourNumner") }}</label>
          <div id="tel_info">
            <div for="tel" id="telLabel">+964</div>
            <input type="tel" v-model="phoneNumber" id="tel" placeholder="75X XXX XXXX" v-mask="['75# ### ####']" />
          </div>
          <button class="btn" @click="generateScript" id="cta_button">
            {{ $t("sendSms") }}
          </button>
          <label class="desc">{{ $t("subscribeInfo", { service_name: serviceData["title_" + $i18n.locale] }) }}</label>
        </div>
        <div id="comedy_logo">
          <div class="comedyImg">
            <img :src="serviceData.icon" alt="" />
          </div>
          <label class="title">{{ serviceData["title_" + $i18n.locale] }}</label>
        </div>
      </div>
    </div>

    <div class="Languages" v-if="!isSelectLanguage">
      <div class="globeIcon"><i class="fa fa-globe"></i></div>
      <div class="breakline"></div>
      <button @click="changeLang('ku')" class="btn-lang">کوردی</button>
      <button @click="changeLang('ar')" class="btn-lang">عربی</button>
      <button @click="changeLang('en')" class="btn-lang">English</button>
    </div>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import notifyMessage from "../mixins/notifyMessage";
import servicesData from "@/mixins/servicesData";
import encryptionService from "@/mixins/encrption";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "ComedyTime",
  mixins: [notifyMessage],
  components: {
    Loading
  },
  data() {
    return {
      mainmessage: "Points: ",
      phoneNumber: "",
      service: [],
      serviceData: {},
      primaryColor: "",
      isLoading: false,
      isSelectLanguage: false,
    };
  },
  directives: { mask },
  methods: {
    async generateScript() {
      this.isLoading = true;
      if (this.phoneNumber.length != 12) {
        this.errorMessage(this.$t("invalidPhone"));
        this.isLoading = false;
        return;
      }
      var phone = "964" + this.phoneNumber.replaceAll(" ", "");
      var uuid = "Td-Zone-App-" + this.$uuid.v4();
      var timestamp = this.getTimeStamp();
      this.$store.commit("setPhoneNumber", phone);
      this.$store.commit("setTi", uuid);

      delete this.$http.defaults.headers.common["tornet_header"];
      delete this.$http.defaults.headers.common["lang"];

      this.$http
        .get(
          this.korekBaseUrl +
          "/dcbprotect.php?action=script&ti=" +
          uuid +
          "&ts=" +
          timestamp +
          "&te=%23cta_button&servicename=TDSZone&merchantname=Tornet"
        )
        .then((response) => {
          var s = response.data.s;

          const script = document.createElement("script");
          script.type = "text/javascript";
          script.text = s;
          document.head.appendChild(script);
          const ev = new Event("DCBProtectRun");
          document.dispatchEvent(ev);

          this.sendSMS();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.errorMessage(this.$t("error"));
        });
    },
    sendSMS() {
      this.$http.defaults.headers.common["tornet_header"] = encryptionService.encrypt(
        "U!vYCRANZz#)HxrTORnetw9kEsXf&8uF(*%2SL+h^$dDI3mtX&R@NH3^s%e67zMWKqjgpuI!JVhY2d5b4c9SCw"
      );

      this.$http.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang");

      var headers = {};


      var uuid = "Td-Zone-App-" + this.$uuid.v4();

      let phoneNumber = this.$store.state.phoneNumber;
      this.$http
        .get(
          this.baseUrl +
          "/dcp/pincode2/" +
          phoneNumber +
          "/" +
          this.service.code +
          "/" +
          this.service["code_" + this.$i18n.locale] +
          "/" +
          this.service.spid +
          "/" +
          uuid + "?service_id=" + this.service.id,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == "Success") {
            this.isLoading = false;

            if (localStorage.getItem("token")) {
              headers = {
                Authorization: "Bearer " + localStorage.getItem("token"),
              };
            }

            if (response.data.is_subscribed == true) {

              localStorage.setItem("token", response.data.token);

              this.$router.push("/categories/" + this.serviceData.title);
            } else {
              this.$router.push("/otp/" + this.serviceData.title);
            }

          } else {

            this.isLoading = false;
            this.errorMessage(response.data.msg);
          }

        })
        .catch(() => {
          this.isLoading = false;

          this.errorMessage(this.$t("error"));
        });
    },
    getTimeStamp() {
      return parseInt(new Date().getTime() / 1000);
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("tdZone_lang", lang);
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang");
      this.isSelectLanguage = true;
    },
    getServices() {
      this.$http.defaults.headers.common["tornet_header"] = encryptionService.encrypt(
        "U!vYCRANZz#)HxrTORnetw9kEsXf&8uF(*%2SL+h^$dDI3mtX&R@NH3^s%e67zMWKqjgpuI!JVhY2d5b4c9SCw"
      );
      this.$http
        .get(this.baseUrl + "/services/" + this.serviceData.id)
        .then((response) => {
          this.service = response.data;
        });
    },
    checkToken() {
      this.isLoading = true;

      this.$http
        .get(this.baseUrl + "/user/checkToken/" + this.serviceData.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.status == "Success") {
            this.$i18n.locale = response.data.user_lang;
            localStorage.setItem("tdZone_lang", response.data.user_lang);
            this.$http.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang");
            this.$router.push("/categories/" + this.serviceData.title);
          }
        });

      this.isLoading = false;
    }
  },
  mounted() {
    if (this.$route.params.lang) {
      this.changeLang(this.$route.params.lang);
    }

    servicesData.find((service) => {
      if (this.$route.params.id) {
        if (service.title == this.$route.params.id) {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
          this.getServices();
        }
      } else {
        if (service.title == "comedy") {
          this.serviceData = service;
          this.primaryColor = service.primary_color;
          this.getServices();
        }
      }
    });

    // to check if that user already subscribed to this service or no
    if (localStorage.getItem("token")) {
      this.checkToken();
    }

  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

/* Comedy New CSS */
body {
  margin: 0px !important;
  padding: 0px !important;
}

.sec_content {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
}

.sec_content::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;

  @media (max-width: 768px) {
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
  }
}

.sec_content {
  width: 100%;

  #topbar {
    position: relative;
    height: auto;
    display: flex;
    padding-left: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0rem;

    @media (max-width: 768px) {
      display: none;
    }

    #logo {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 768px) {
      #logo {
        margin: 0px;
      }
    }
  }

  .Languages {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: end;
    }



    .globeIcon {
      margin-bottom: 1rem;
      font-size: 30px;

      svg {
        color: v-bind(primaryColor);
      }
    }

    .breakline {
      width: 300px;
      height: 2px;
      background-color: v-bind(primaryColor);
      margin-bottom: 1rem;
    }

    button {
      outline: none;
      width: 300px;
      margin-bottom: 1rem;
      height: 45px;
      line-height: 45px;
      border: none;
      border-radius: 3px;
      background-color: v-bind(primaryColor);
      color: white;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .formContent {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;

    .form-content {
      display: flex;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 0px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (min-width: 769px) and (max-width: 1024px) {
        width: 90%;
      }

      @media (min-width: 1024px) and (max-width: 1440px) {
        width: 80%;
      }

      @media (min-width: 1440px) {
        width: 60%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 360px;
      height: 100%;

      @media (max-width: 425px) {
        width: 300px;
        margin-top: 250px;
      }

      @media (min-width: 425px) and (max-width: 768px) {
        margin-top: 300px;
      }

      #formLabel {
        font-size: 30px;
        color: v-bind(primaryColor);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 2rem;

        @media (max-width: 425px) {
          font-size: 25px !important;
          margin-bottom: 1rem;
        }
      }

      #tel_info {
        width: 100%;
        //border: 1px solid #f1f1f1;
        display: flex;
        align-items: center;

        margin-bottom: 2rem;
        scroll-padding-top: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        background-color: none;

        @media (max-width: 425px) {
          justify-content: center;
          margin-bottom: 1rem;
        }

        #telLabel {
          color: v-bind(primaryColor);
          font-size: 35px;
          font-weight: bold;
          border-right: 2px soild #aaa;

          @media (max-width: 425px) {
            font-size: 25px;
          }
        }

        input[type="tel"] {
          border: none;
          outline: none;
          height: 40px;
          color: v-bind(primaryColor);
          padding-left: 0.4rem;
          font-weight: bold;
          font-size: 35px;
          width: inherit;

          @media (max-width: 425px) {
            font-size: 25px;
          }
        }

        ::placeholder {
          color: #aaa;
          font-weight: bold;
          font-size: 35px;

          @media (max-width: 425px) {
            font-size: 25px;
          }
        }
      }

      .btn {
        width: 100%;
        height: 70px;
        font-size: 30px;
        background: v-bind(primaryColor);
        color: white;
        border-radius: 15px;
        outline: none;
        border: none;
        font-weight: 30px;
        cursor: pointer;

        @media (max-width: 425px) {
          height: 60px;
          font-size: 25px;
        }
      }

      label.desc {
        width: 100%;
        margin-top: .8rem;
        text-align: center;
        color: v-bind(primaryColor);
        line-height: 1.5;

        @media (max-width: 768px) {
          margin-bottom: 2rem;
        }
      }

    }

    #comedy_logo {
      display: flex;
      flex-direction: column;
      border-radius: 6px;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
      }

      .comedyImg {
        filter: drop-shadow(8px 8px 8px #aaa);
        border-radius: 40px;
        padding: 12px;
        width: 215px;
        height: 205px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 425px) {
          width: 150px;
          height: 135px;
        }
      }

      .comedyImg img {
        border-radius: 30px;
        width: 100%;
        height: 100%;
      }

      label.title {
        margin-top: 1rem;
        text-align: center;
        font-size: 40px;
        font-weight: 768;
        color: v-bind(primaryColor);

        @media (max-width: 425px) {
          font-size: 30px;
        }

        @media (max-width: 768px) {
          color: #fff;
          font-size: 30px;
        }
      }


    }
  }
}

.sec_content[data-sec="learn"]::after {
  @media (min-width: 769px) {
    background-image: var(--learn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--learn-mobile-image);
  }
}

.sec_content[data-sec="comedy"]::after {
  @media (min-width: 769px) {
    background-image: var(--comedy-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--comedy-mobile-image);
  }
}

.sec_content[data-sec="game"]::after {
  @media (min-width: 769px) {
    background-image: var(--game-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--game-mobile-image);
  }
}

.sec_content[data-sec="knowledge"]::after {
  @media (min-width: 769px) {
    background-image: var(--kn-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--kn-mobile-image);
  }
}

.sec_content[data-sec="ladies"]::after {
  @media (min-width: 769px) {
    background-image: var(--lad-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--lad-mobile-image);
  }
}

.sec_content[data-sec="entertainment"]::after {
  @media (min-width: 769px) {
    background-image: var(--ent-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--ent-mobile-image);
  }
}

.sec_content[data-sec="my religion"]::after {
  @media (min-width: 769px) {
    background-image: var(--rel-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--rel-mobile-image);
  }
}

.sec_content[data-sec="meal"]::after {
  @media (min-width: 769px) {
    background-image: var(--mea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--mea-mobile-image);
  }
}

.sec_content[data-sec="weather"]::after {
  @media (min-width: 769px) {
    background-image: var(--wea-desktop-image);
  }

  @media (max-width: 768px) {
    background-image: var(--wea-mobile-image);
  }
}
</style>
