const ar = {
  enterYourNumner: "أدخل رقمك",
  login:"تسجيل الدخول",
  sendSms:"إرسال رسائل نصية قصيرة",
  otpMsg: "أدخل كلمة المرور",
  subscribe:"اشترك",
  watchFree:"شاهد مجانا",
  points:"النقاط",
  video:"فيديو",
  image:"صورة",
  text:"نص",
  companyName:"تورنت",
  homeTitle:"خدمات الرقمية",
  success:"تم بنجاح",
  confirmMsg:"هل أنت متأكد؟",
  cancel:"إلغاء",
  close:"أغلق",
  yes:"نعم",
  invalidPhone:"رقم الهاتف هذا غير صالح",
  error:"حدث خطأ",
  subscribeInfo:'مرحبًا بك في {service_name}! ستحصل على 5 نقاط كل يوم باشتراك 300 د.ع يومياً. لإلغاء الاشتراك، أرسل 0 إلى 3801 (مجانًا)'
};

export default ar;