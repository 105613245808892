import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            phoneNumber: "",
            ti:"",
        }
    },
    mutations: {
        setPhoneNumber(state,phone) {
            state.phoneNumber=phone;
        },
        setTi(state,transcationID) {
            state.ti=transcationID;
        },
    }
});