<template>
  <section class="sec_content" :data-sec="serviceData.title">
    <div id="comedyLogo">
      <div class="comedyLogoImg">
        <router-link to="/">
          <img :src="serviceData.icon" :alt="serviceData.title" />
        </router-link>
      </div>

      <label>{{ serviceData["title_" + $i18n.locale] }}</label>
    </div>

    <div id="topbar">
      <router-link to="/">
        <!-- <img id="logo" src="../assets/logo.png" alt="tdzone"/> -->
      </router-link>
      <div class="topInfo">
        <p class="title">{{ serviceName }}</p>
        <p class="points subtitle">{{ $t("points") }} : {{ userPoints }}</p>
      </div>
    </div>

    <div class="formContent">
      <div class="form-content">
        <div class="info">
          <p class="points">{{ $t("points") }} : {{ userPoints }}</p>
          <div class="buttons">
            <button v-if="isHasVideo" :class="{ btn: true, active: categoryType == 'Video' }"
              @click="searchContent('Video')">
              {{ $t("video") }}
            </button>
            <button v-if="isHasImage" :class="{ btn: true, active: categoryType == 'Image' }"
              @click="searchContent('Image')">
              {{ $t("image") }}
            </button>
            <button v-if="isHasText" :class="{ btn: true, active: categoryType == 'Text' }"
              @click="searchContent('Text')">
              {{ $t("text") }}
            </button>
          </div>
        </div>
        <!-- <div id="comedy_logo" @click="showFreeContent()">
          <div class="comedyImg">
            <img id="media" :src="serviceData.thumbnail" alt="" />
            <img id="playIcon" src="../assets/icons/play.png" alt="" />
          </div>
        </div> -->



        <!--New Slider -->
        <div style="margin-left:1rem;width:100%" class="itemCarousels" v-if="items.length > 0">
          <carousel v-model="slideNumber" items-to-scroll="1" items-to-show="10" :touch-drag="true" snap-align="start"
            :mouse-drag="true" :breakpoints="{
              // 700px and up
              300: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 2,
                snapAlign: 'start',
              },
              425: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 4,
                snapAlign: 'start',
              },
              768: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 6,
                snapAlign: 'start',
              },
              1000: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 8,
                snapAlign: 'start',
              },
              1100: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 9,
                snapAlign: 'start',
              },
              1200: {
                itemsToScroll: 1,
                mouseDrag: true,
                touchDrag: true,
                itemsToShow: 10,
                snapAlign: 'start',
              },
            }">
            <slide class="itemCarousel" v-for="item in items" :key="item">
              <div :class="{ 'card': true, 'lock': content.lockType == 'lock' }"
                @click="showContent(content.id, content.content_type, content.lockType)" v-for="content in item"
                :key="content">
                <!-- <img v-if="(categoryType != 'Video' && content['thumbnail'])" :src="this.contentPath +
                '/' +
                content['thumbnail']" class="bgImg" alt="">
                <img v-if="(categoryType == 'Video' && content['thumbnail'])" :src="
                content['thumbnail']" class="bgImg" alt=""> -->
                <img class="bgImg" alt=""  v-if="content.lockType == 'lock'" :src="serviceData.thmb" />
                <img class="bgImg" v-if="categoryType == 'Image' && content.lockType == 'unlock'" alt="" :src="contentPath +
                '/' +
                content['content_pic_' + this.$i18n.locale]" />
                <img v-if="(categoryType != 'Image' && content['thumbnail'])" :src="
                content['thumbnail']" class="bgImg" alt=""> 
              </div>
            </slide>
          </carousel>
        </div>


        <div class="mobileCarousels">
          <div class="itemCarousel" v-for="item in items" :key="item">
            <div :class="{ 'card': true, 'lock': content.lockType == 'lock' }"
              @click="showContent(content.id, content.content_type, content.lockType)" v-for="content in item"
              :key="content">
              <!-- <img :src="this.contentPath +
              '/' +
              content['content_pic_' + this.$i18n.locale]" v-if="content['content_pic_' + this.$i18n.locale]"
                class="bgImg" alt=""> -->
                <img class="bgImg" alt=""  v-if="content.lockType == 'lock'" :src="serviceData.thmb" />
                <img class="bgImg" v-if="categoryType == 'Image' && content.lockType == 'unlock'" alt="" :src="contentPath +
                '/' +
                content['content_pic_' + this.$i18n.locale]" />
                <img v-if="(categoryType != 'Image' && content['thumbnail'])" :src="
                content['thumbnail']" class="bgImg" alt=""> 
              <!-- <img v-if="content.lockType == 'lock'" src="../assets/icons/lock.png" alt="" /> -->
            </div>
          </div>
        </div>


      </div>
    </div>

    <div :class="{ videoPopup: true, active: showPopup,disabled:!showPopup }" V-if="showPopup">
      <div @click="closeContent">
        <i class="fa fa-times closeBtn"></i>
      </div>

      <iframe :src="videoSrc" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div :class="{ imagePopup: true, active: showImagePopup,disabled:!showImagePopup }" id="imagePopup">
      <div @click="closeContent">
        <i class="fa fa-times closeBtn"></i>
      </div>
      <!-- <div class="prev" @click="prevImageSlide">
        <i class="fa-solid fa-chevron-left"></i>
      </div>
      <div class="next" @click="nextImageSlide">
        <i class="fa-solid fa-chevron-right"></i>
      </div> -->


      <img :src="imgSrc" alt="" />

    </div>

    <div :class="{ textPopup: true, active: showTextPopup,disabled:!showTextPopup }">
      <div @click="closeContent">
        <i class="fa fa-times closeBtn"></i>
      </div>

      <div class="textContent">
        <p class="title">
          {{ title }}
        </p>
        <p class="content">
          {{ content }}
        </p>
      </div>
    </div>

    <div :class="{
      overlay: true,
      active: showPopup || showImagePopup || showTextPopup,
    }"></div>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import notify from "../mixins/notifyMessage.vue";
import servicesData from "@/mixins/servicesData";


import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'


export default {
  name: "ComedyTime",
  components: {
    Carousel,
    Slide
  },
  mixins: [notify],
  data() {
    return {
      slideNumber: 0,
      mainmessage: "Points: ",
      phoneNumber: "",
      count: 0,
      imageCount: 0,
      categoryType: "",
      isHasVideo: false,
      isHasImage: false,
      isHasText: false,
      showPopup: false,
      showImagePopup: false,
      showTextPopup: false,
      items: [],
      itemsLength: 0,
      userPoints: 0,
      serviceName: "",
      title: "",
      content: "",
      videoSrc: "",
      imgSrc: "",
      is_subscribed: false,
      parent_service_id: 0,
      serviceData: {},
      primaryColor: "",
    };
  },
  directives: { mask },
  methods: {
    showContent(id, cntent_type, isLocked) {
      if (isLocked == "unlock") {
        this.$http.get(this.baseUrl + "/contents/" + id).then(
          (response) => {
            console.log(response.data);
            this.title = response.data["titel_" + this.$i18n.locale];

            if (cntent_type == "Text") {
              this.content = response.data["desc_" + this.$i18n.locale];
              this.showTextPopup = true;
            } else if (cntent_type == "Image") {
              this.imgSrc =
                this.contentPath +
                "/" +
                response.data["content_pic_" + this.$i18n.locale];
              this.showImagePopup = true;
            } else if (cntent_type == "Video") {
              this.videoSrc = response.data["video_url_" + this.$i18n.locale];
              this.showPopup = true;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        // this.confirm().then((response) => {
        //   if (response) {
            this.$http
              .post(
                this.baseUrl + "/contents/unlock",
                {
                  parent_service_id: this.parent_service_id,
                  service_id: this.$route.params.id,
                  content_id: id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.msg) {
                  this.errorMessage(response.data.msg);
                } else {
                  let content = response.data.content_info;
                  this.title = content['titel_' + this.$i18n.locale];
                  this.userPoints = response.data.current_point;
                  if (cntent_type == "Text") {
                    this.content = content['desc_' + this.$i18n.locale];
                    this.showTextPopup = true;
                  } else if (cntent_type == "Image") {
                    this.imgSrc = this.contentPath + "/" + content['content_pic_' + this.$i18n.locale];
                    this.showImagePopup = true;
                  } else if (cntent_type == "Video") {
                    this.videoSrc = content['video_url_' + this.$i18n.locale];
                    this.showPopup = true;
                  }
                  this.searchContent(this.categoryType);
                }
              });
        //   }
        // });
      }
    },
    closeContent() {
      this.videoSrc="";
      this.imgSrc="";
      this.content="";
      this.showPopup = false;
      this.showImagePopup = false;
      this.showTextPopup = false;
    },
    showFreeContent() {
      this.videoSrc = this.serviceData.video;
      this.showPopup = true;
    },
    searchContent(categoryType) {
      this.categoryType = categoryType;

      var url = "";
      if (this.is_subscribed == true) {
        url =
          this.baseUrl +
          "/user/contents/" +
          this.$route.params.id +
          "?content_type=" +
          this.categoryType;
      } else {
        url =
          this.baseUrl +
          "/contents/grid/" +
          this.$route.params.id +
          "?content_type=" +
          this.categoryType;
      }

      this.$http
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.items = [];

          var resItems = [];
          if (this.is_subscribed == true) {
            resItems = response.data;
          } else {
            resItems = response.data.data;
          }

          // var resItems = response.data.data;

          this.itemsLength = resItems.length;

          try {
            let element = 0;
            for (let index = 0; index < this.itemsLength; index = index + 2) {
              this.items[element] = [];
              this.items[element].push(resItems[index]);
              if (resItems[index + 1]) {
                this.items[element].push(resItems[index + 1]);
              }
              element++;
            }
            this.resetCarousel();
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error);
          this.items = [];
          this.itemsLength = 0;
        });
    },
    checkAvailabilityContentTypes() {

      this.$http.get(this.baseUrl + "/contents/" + this.$route.params.id + "/hascontent")
        .then((response) => {
          console.log(response);
          var data = response.data;
          let categoryType = "";
          if (data.Video == true) {
            this.isHasVideo = true;
            categoryType = "Video";
            this.categoryType = "Video";
          }
          if (data.Image == true) {
            this.isHasImage = true;
            if (categoryType == "")
              this.categoryType = "Image";
          }
          if (data.Text == true) {
            this.isHasText = true;
            if (categoryType == "")
              this.categoryType = "Text";
          }
          if (this.categoryType != "")
            this.searchContent(this.categoryType);
        });

    },
    checkUser() {
      this.$http
        .post(
          this.baseUrl + "/user/check",
          {
            parent_service_id: this.parent_service_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(
          (response) => {
            if (response.data.is_subscribed == 1) {
              this.is_subscribed = true;
              this.checkAvailabilityContentTypes();
            } else {
              this.is_subscribed = false;
              this.checkAvailabilityContentTypes();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getPoints(serviceID) {
      this.$http
        .get(this.baseUrl + "/user_point/" + serviceID, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.userPoints = response.data.point;
        });
    },
    checkService() {
      this.$http
        .get(this.baseUrl + "/services/parent/" + this.$route.params.id)
        .then((response) => {
          this.parent_service_id = response.data.parent_id;

          servicesData.find((service) => {
            if (service.id == response.data.parent_id) {
              this.serviceData = service;
              this.primaryColor = service.primary_color;
            }
          });
          this.checkUser();
          this.getPoints(this.parent_service_id);
          this.serviceName = response.data["service_name_" + this.$i18n.locale];
        });
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.checkService();
    } else {

      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.sec_content {
  overflow-x: hidden;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  position: relative;

  #comedyLogo {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;

    @media (min-width: 320px) and (max-width: 768px) {
      top: 2rem;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      justify-content: start;
    }

    @media (min-width: 769px) {
      display: flex;
      flex-direction: column;
    }

    .comedyLogoImg {
      filter: drop-shadow(8px 8px 8px #aaa);
      border-radius: 40px;
      padding: 12px;
      width: 190px;
      height: 175px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media (min-width: 320px) and (max-width: 768px) {
        margin-right: 2rem;

        border-radius: 20px;
        width: 120px;
        height: 110px;
      }
    }

    .comedyLogoImg img {
      border-radius: 30px;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }

    label {
      margin-top: 1rem;
      text-align: center;
      font-size: 40px;
      font-weight: 768;
      color: #ffff;

      @media (max-width: 768px) {
        color: #fff;
        font-size: 30px;
        position: relative;
        top: 25px;
      }

      @media (min-width: 768px) and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .videoPopup {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(66, 53, 53);
    width: fit-content;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 999;
    overflow: hidden;

    iframe {
      @media (max-width: 768px) {
        width: calc(92vw);
        height: 315px;
      }

      @media (min-width: 768px) {
        width: 560px;
        height: 315px;
      }
    }

    .closeBtn {
      position: absolute;
      top: 0px;
      right: 10px;
      color: red;
      font-size: 20px;
      overflow: hidden;
      cursor: pointer;
      cursor: pointer;
    }
  }

  .imagePopup {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(66, 53, 53);
    width: fit-content;
    //  max-height: 80vh;
    aspect-ratio: 1/1;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 999;
    overflow: hidden;

    @media (max-width: 768px) {
      width: calc(96vw);
      height: fit-content;
    }

    @media (min-width: 768px) {
      width: fit-content;
      height: fit-content;
    }

    .prev,
    .next {
      position: absolute;
      top: 50%;
      z-index: 999;
      cursor: pointer;
      font-weight: 1200;
      font-size: 40px;
      color: #ddd;
    }

    .prev {
      left: 20px;
      transform: translateY(-50%);
    }

    .next {
      right: 20px;
      transform: translateY(-50%);
    }

    img {
      width: 100%;
      height: 100%;

    }

    .closeBtn {
      position: absolute;
      top: 0px;
      right: 10px;
      color: red;
      font-size: 20px;
      z-index: 999;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .textPopup {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(251, 248, 248);
    overflow-y: scroll !important;
    width: fit-content;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 999;
    overflow: hidden;
    text-align: justify;
    line-height: 1.6;
    border-radius: 5px;

    .closeBtn {
      cursor: pointer;
    }

    @media (min-width: 768px) {
      width: 500px !important;
      height: 768px !important;
    }

    @media (max-width: 768px) {
      width: calc(96vw) !important;
      height: 96vh !important;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .videoPopup.active,
  .imagePopup.active,
  .textPopup.active {
    display: block;
    z-index: 999;
  }

  .videoPopup.disabled,
  .imagePopup.disabled,
  .textPopup.disabled {
    display: none;
  }

  .overlay {
    background-color: black;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transition: 0.2s;
  }

  .overlay.active {
    opacity: 0.5;
  }
}

.sec_content::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;

  @media (max-width: 768px) {
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
  }
}

.sec_content {
  width: 100%;

  #topbar {
    position: relative;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0rem;
    z-index: 99;

    @media (max-width: 768px) {
      margin: 0rem;
    }

    #logo {
      width: 200px;
      height: 60px;
    }

    @media (max-width: 768px) {
      #logo {
        display: none;
      }
    }

    .topInfo {
      .title {
        font-size: 30px;
      }

      .subtitle {
        font-size: 20px;
      }

      .title,
      .subtitle {
        color: v-bind(primaryColor);
        font-weight: bold;
        margin: 0px;
        padding: 0px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .formContent {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999 !important;

    .form-content {
      display: flex;

      div {
        //  width: fit-content;
      }

      .swSlider {
        margin-left: 10px;
        width: iniital !important;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: start;
        width: 100%;
        margin-top: 30px;
      }

      @media (min-width: 320px) and (max-width: 768px) {
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }

      @media (min-width: 1024px) and (max-width: 1440px) {
        width: 90%;
      }

      @media (min-width: 1440px) {
        width: 80%;
      }
    }

    .info {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // width: 320px;

      @media (max-width: 768px) {
        width: 100% !important;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        z-index: 99999999999999999999999;

        @media (max-width: 768px) {
          width: 80%;
          flex-direction: row;
          justify-content: space-around;
        }

        @media (min-width: 320px) and (max-width: 768px) {
          margin-left: 0.5rem;
        }

        .btn {
          height: 80px;
          width: 100px;
          font-size: 20px;
          background-color: white;
          cursor: pointer;
          color: white;
          border-radius: 10px;
          outline: none;
          border: none;
          font-weight: 30px;
          color: v-bind(primaryColor);
          margin-bottom: 0.5rem;
          filter: drop-shadow(8px 8px 8px #aaa);

          @media (min-width: 320px) and (max-width: 768px) {
            margin-top: 0.2rem;
            width: 75px;
            font-size: 15px;
            height: 55px;
          }

          @media (min-width: 768px) and (max-width: 890px) {
            margin-top: 0.2rem;
            width: 80px;
            height: 65px;
          }
        }

        .active {
          background: v-bind(primaryColor);
          color: white;
        }
      }

      @media (max-width: 768px) {
        margin-top: 140px;
        width: 100%;
      }

      .points {
        color: v-bind(primaryColor);
        font-weight: bold;
        font-size: 30px;

        @media (min-width: 769px) {
          display: none;
        }
      }
    }

    #comedy_logo {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background-color: transparent;
      z-index: 999;
      cursor: pointer;

      @media (max-width: 768px) {
        margin: 0px;
        margin-top: 1.5rem;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (min-width: 769px) {
        min-width: 420px;
      }

      .comedyImg {
        filter: drop-shadow(8px 8px 8px #aaa);
        border-radius: 4px;

        height: 280px;
        background-color: transparent;
        position: relative;

        @media (min-width: 320px) and (max-width: 768px) {
          min-width: calc(75%);
          width: 300px;
          height: 200px;
        }

        @media (min-width: 768px) and (max-width: 890px) {
          margin-top: 0.5rem;
          width: 300px;
          height: 210px;
        }

        @media (min-width: 890px) and (max-width: 968px) {
          margin-top: 0.5rem;
          width: 320px;
          height: 260px;
        }

        @media (min-width: 968px) and (max-width: 1024px) {
          margin-top: 0.5rem;
          width: 350px;
          height: 260px;
        }

        @media (min-width: 1024px) and (max-width: 1200px) {
          width: 380px;
        }

        @media (min-width: 1140px) and (max-width: 1200px) {
          width: 450px;
        }
      }

      .comedyImg img#media {
        border-radius: 30px;
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media (min-width: 768px) and (max-width: 768px) {
          width: 90%;
        }
      }

      .comedyImg img#playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;

        @media (min-width: 768px) and (max-width: 890px) {
          width: 80px;
          height: 80px;
        }
      }

      label {
        margin-top: 1rem;
        text-align: center;
        font-size: 30px;
        font-weight: 768;
        color: v-bind(primaryColor);

        @media (max-width: 768px) {
          color: #fff;
          font-size: 30px;
        }
      }
    }



    /* just for test */


    /*  .swSlider {
      position: relative;
      margin-left: 0rem;
      padding: 0.5rem;
      display: flex;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 90% !important;
        position: relative;
        left: 5%;
        justify-content: start !important;
        height: 300px;
      }

      .prev,
      .next {
        position: absolute;
        top: 50%;
        z-index: 999;
        cursor: pointer;
        font-weight: 1200;
        font-size: 20px;
        color: v-bind(primaryColor);
      }

      .prev {
        left: 0px;
        transform: translateY(-50%);
      }

      .next {
        right: 0px;
        transform: translateY(-50%);
      }

      @media (max-width: 768px) {
        margin: 0px;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }

      .carousel {
        z-index: 999;
        display: flex;
        transition: 0.5s;
      }

      .itemCarousel {
        display: flex;
        flex-direction: column;

        div:nth-child(odd) {
          margin-bottom: 1.5rem;
        }
      }

      .card {
        background: v-bind(primaryColor);
        height: 120px;
        width: 130px;
        position: relative;
        border-radius: 10px;
        margin-right: 1rem;
        background-size: cover;
        cursor: pointer;
        @media (max-width: 768px) {
          width: 130px;
          height: 300px;
        }

        @media (min-width: 768px) and (max-width: 768px) {
          width: 80px;
          height: 80px;
        }

        @media (min-width: 768px) and (max-width: 890px) {
          width: 90px;
          height: 90px;
        }

        @media (max-width: 1024) {
          width: 110px;
          height: 110;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
        }
      }
    } */
  }
}

.sec_content[data-sec="learn"]::after {
  @media (min-width: 768px) {
    background-image: var(--learn-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--learn-mobile2-image);
  }
}

.sec_content[data-sec="comedy"]::after {
  @media (min-width: 768px) {
    background-image: var(--comedy-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--comedy-mobile2-image);
  }
}

.sec_content[data-sec="knowledge"]::after {
  @media (min-width: 768px) {
    background-image: var(--kn-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--kn-mobile2-image);
  }
}

.sec_content[data-sec="ladies"]::after {
  @media (min-width: 768px) {
    background-image: var(--lad-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--lad-mobile2-image);
  }
}

.sec_content[data-sec="entertainment"]::after {
  @media (min-width: 768px) {
    background-image: var(--ent-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--ent-mobile2-image);
  }
}

.sec_content[data-sec="my religion"]::after {
  @media (min-width: 768px) {
    background-image: var(--rel-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--rel-mobile2-image);
  }
}

.sec_content[data-sec="meal"]::after {
  @media (min-width: 768px) {
    background-image: var(--mea-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--mea-mobile2-image);
  }
}

.sec_content[data-sec="weather"]::after {
  @media (min-width: 768px) {
    background-image: var(--wea-desktop-image);
  }

  @media (max-width: 769px) {
    background-image: var(--wea-mobile2-image);
  }
}


.carousel {
  width: 100%;
}


@media(max-width:425px) {
  .itemCarousels {
    display: none;
  }
}

.itemCarousel {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  padding: .5rem;

  @media (max-width:425px) {
    display: flex;
    justify-content: space-around;
  }

  @media (min-width:426px) {
    div:nth-child(odd) {
      margin-bottom: 1.5rem;
    }
  }

}




/*.lock {
  filter: blur(2px);
}*/

/*.lock::after {
  position: absolute;
  background-image: url("../assets/icons/lock.png");
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  content: "";
  background-size: cover;
}*/

.card {
  background: v-bind(primaryColor);
  // border: 1px solid v-bind(primaryColor);
  width: 100% !important;
  aspect-ratio: 1/1;
  position: relative;
  border-radius: 10px;
  //margin-right: 1rem;
  background-size: cover;
  cursor: pointer;


  @media (max-width: 425px) {
    width: 40% !important;
    //height: 80px;
  }



  @media (min-width: 769px) and (max-width: 890px) {
    width: 100% !important;
    height: 90px;
  }

  @media (max-width: 1024) {
    width: 100% !important;
    height: 110;
  }

  .bgImg {
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // filter: blur(2px);
  }

  /*img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }*/
}


.mobileCarousels {
  @media(min-width:426px) {
    display: none;
  }

  align-items: center;

  .itemCarousel {
    display: flex;
    flex-direction: row;
    //justify-content: start;

    height: fit-content !important;

    .card {
      aspect-ratio: 1/1;
    }
  }
}
</style>
