import HomePage from "./components/Home.vue";
import Subscribtion from "./components/Subscribtion.vue";
import OtpScreen from "./components/Otp.vue";
import CategoriesScreen from "./components/Categories.vue";
import ItemsScreen from "./components/Items.vue";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:id?",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/subscribtion/:id/:lang?",
    name: "subscribtion",
    component: Subscribtion,
  },
  {
    path: "/otp/:service",
    name: "Otp",
    component: OtpScreen,
  },
  {
    path: "/categories/:id",
    name: "categories",
    component: CategoriesScreen,
  },
  {
    path: "/items/:id",
    name: "Items",
    component: ItemsScreen,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
