import axios from "axios";

import encryptionService from "@/mixins/encrption";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  setHeader() {
    axios.defaults.headers.common["tornet_header"] = encryptionService.encrypt(
      "U!vYCRANZz#)HxrTORnetw9kEsXf&8uF(*%2SL+h^$dDI3mtX&R@NH3^s%e67zMWKqjgpuI!JVhY2d5b4c9SCw"
    );
    axios.defaults.headers.common["lang"] = localStorage.getItem("tdZone_lang") ?? "en";
  },
};

export default ApiService;
